<template>
    <div class="">
        <div class="">
            <apexchart v-if="loaded" :height="'350px'" :options="chartOptions" :series="series"></apexchart>
            <div v-if="!series">
                <p class="text-center alert" >No Data to display</p>
            </div>
        </div>
    </div><!-- .card -->
</template>

<script>
export default {
    data() {
        return {
          ticketStats: null,
          widgetFetcher: {
            start: this.$moment().startOf('month'),
            end: this.$moment().endOf('month'),
          },
          loaded: false,
          series: [],
          chartOptions: {
            colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7'],
            fill: {
              colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
              }
            },
            markers: {
              colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
            },
            chart: {
              width: 200,
              height: 250,
              type: 'bar'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              categories: []
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm' + 'Of the Month'
              },
            },
          },
        }
    },
    created() {
        this.getStats()
    },
    methods:{
        getStats() {
            this.loaded = false
            this.$http.post('/widgets/Get_Top5ContactTicketsChart/', this.widgetFetcher)
            .then((response) => {
              this.ticketStats = response.data
              let xaxis  = [] 
              let series  = [{name: 'Opened', data: []},{name: 'Completed', data: []},{name: 'Missed SLA', data: []}]
              response.data.forEach(element => {
                xaxis.push(element.name)
                series[0].data.push(element.openedCount)
                series[1].data.push(element.closedCount)
                series[2].data.push(element.missedSLACount)
                //alert(element.openedCount)
              });
              this.chartOptions.xaxis.categories = xaxis
              this.series = series
            
                this.loaded = true
            })
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment().startOf('month'),
                this.widgetFetcher.end = this.$moment().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(2, 'months').endOf('month')
            }
            this.getStats()
        },
    }
}
</script>

<style>

</style>