<template>
    <div>
        <div class="card card-bordered">
            <div class="card-inner">
                <div class="row">
                    <div class="col-12">
                        <div class="card-title">
                            <h4 class="title">Reports</h4>
                            <p style="fontSize: 16px;">Here you can run your reports. Click the report below you would like to view.</p>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketUserSummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By User</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ticketsByUsers/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketIssueTypeSummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By Issue Type</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ticketsByIssueType/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketAccountSummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By Account</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <top5AccountsTickets/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketTeamSummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By Team</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ticketsByTeam/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketPrioritySummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By Priority</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ticketsByPriority/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'ticketContactSummary'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Tickets By Contact</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <top5ContactsTickets/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-3">
                                    <router-link :to="{ name: 'accountIssues'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">Account Issues</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <top5AccountsTickets/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                  <div class="col-3">
                                    <router-link :to="{ name: 'slaadherence'}">
                                        <div class="card-bordered chart">
                                            <div class="col-12">
                                                <div class="card-header">
                                                    <h5 class="title text-center">SLA Adherence</h5>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <ticketsByUsers/>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ticketsByUsers from "@/components/widgets/desk/reportsIndex/ticketsByUsers.vue"
import ticketsByIssueType from "@/components/widgets/desk/reportsIndex/ticketsByIssueType.vue"
import top5AccountsTickets from "@/components/widgets/desk/reportsIndex/top5AccountsTickets.vue"
import top5ContactsTickets from "@/components/widgets/desk/reportsIndex/top5ContactsTickets.vue"
import ticketsByPriority from "@/components/widgets/desk/reportsIndex/ticketsByPriority.vue"
import ticketsByTeam from "@/components/widgets/desk/reportsIndex/ticketsByTeam.vue"

export default {
    components: { ticketsByUsers, ticketsByIssueType, top5AccountsTickets, top5ContactsTickets, ticketsByPriority, ticketsByTeam },
    data() {
        return {
            
        }
    },
    created(){
        this.getView()
    },
    methods:{
        getView (){ this.$http.get('/ViewAccess/reports')
        .then(() => { 
        })
        .catch(() => { 
        })},
    }
}
</script>

<style scoped>

.chart {
    height: 100%
}

</style>